<template>
  <div class="worktrends">
    <div class="worktitle">
        <span>工作动态</span>
        <span @click="jumpToimgdetail">>>更多</span>
    </div>
    <div class="workcontent">
        <div class="workinfo">
            <div class="workbanners">
                <div class="block">
                <el-carousel trigger="click" height="327px">
                <el-carousel-item 
                v-for="item in imgList"
                :key="item.id"
                @click.native="$goto('/detailShow',{id:item.id})"
                >
                    <!-- <h3 class="small">{{item.smallImage}}</h3> -->
                    <img style="width:100%;height:100%" :src="item.smallImage+'!327x327'" alt="">
                </el-carousel-item>
                </el-carousel>
            </div>
            <div class="mask"></div>
            </div>
            <div class="worklists">
                <ul>
									<template v-for="(item,index) in newsList" >
										<li	v-if="index < 7" :key="item.id" @click="$goto('/detailShow',{id:item.id})">
										<p><i></i><span>{{item.title}}</span></p>
										<span>{{item.publishDate | formateData}}</span>
										</li>
									</template>
                </ul>
            </div>
        </div>
        <div class="donatenum">
            <div class="donatetop">
                <div class="donatetitle">
                  —— 截至{{collectinfo.endDate}} ——
                </div>
            <div class="registernum">
                <h2>四川省造血干细胞库容（人份）</h2>
                <span>{{collectinfo.hematopoietic_capacity}}</span>
            </div>
            <div class="realnum">
               <h2>捐赠造血干细胞例数</h2>
               <span>{{collectinfo.hematopoietic_donors}}</span>
            </div>
            </div>
            <!-- <div class="donatebottom"></div>    -->
			<div class="donatebottom" @click="$goto('/stemcellsRegister')"></div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
     worklist : {
        type: Array,
        default: []
    },
    collectinfo : {
        type: Object,
        default: {}
    }
  },
  name: 'Worktrends',
  watch: {
    worklist() { // 接收父组件传来的参数名
      this.setData();
    }
  },
  data () {
      return {
          imgList:[],
          newsList:[]
      }
    },
  methods : {
    jumpToimgdetail () {
      this.$router.push({
          path: '/imgDetail',
          query: { name: "工作动态", type: 'gxb_gzdt'}
      })
    },
      setData(){
        this.$nextTick(function() {
            let _this = this;
            this.worklist.forEach((item,ind) => {
							_this.newsList.push(item)
                if(_this.imgList.length<3&&item.smallImage){
                    _this.imgList.push(item)
                }
            })
        });
        
      }
  },
}
</script>

<style lang="less" scoped>
.worktrends {
    .worktitle  {
        cursor: pointer;
        width: 845px;
        margin-top: 10px;
        font-size: 23px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        span {
            &:nth-child(1) {
            display: flex;
                &::before {
                content: "";
                display: block;
                height: 20px;
                width: 35px;
                background: url('../../../assets/img/title.png') no-repeat center center;
                }
            }
            &:nth-child(2) {
                font-size: 17px;
                color: #ABABAB;
            }
        }

    }
    .workcontent {
        width: 100%;
        height: 360px;
        background-color: #f5f5f5;
        display: flex;
        justify-content: space-between;
        .workinfo {
          width: 830px;
          height: 360px;
          background: #ffffff;        
          box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
          padding: 0 19px 0 15px;
          display: flex;
          .workbanners {
            position: relative;
            width: 344px;
            height: 327px;
            background-color: skyblue;
            margin-top: 18px;
            margin-right: 15px;
            .el-carousel__item h3 {
                    color: #475669;
                    font-size: 14px;
                    opacity: 0.75;
                    line-height: 150px;
                    margin: 0;
                }

                .el-carousel__item:nth-child(2n) {
                    background-color: #99a9bf;
                }
                
                .el-carousel__item:nth-child(2n+1) {
                    background-color: #d3dce6;
                }
                .mask {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 344px;
                    height: 40px;
                    z-index: 2;
                    // background: #000000;
                    opacity: 0.35;
                }
          }
          .worklists {
            flex: 1;
			padding: 10px 0;
			box-sizing: border-box;
            li {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px !important;
				margin-top: 0 !important;
				padding: 10px 0;
                p {
                    display: flex;
                    width: 304px; 
                    color: #333333;
                    span {
						&:hover {
							color: #d1292e;
						}
                        color: #000000;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                     i {
                    display: block;
                    width: 25px;
                    height: 16px;
                    background: url('../../../assets/img/list-little.png') no-repeat 0 center;
                   }
                }
                span {
                    color: #ABABAB;
                }
                &:first-child {
                    margin-top: 33px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
          }
        }
        .donatenum {
          width: 304px;
          height: 360px;
          background-color: #f5f5f5;
          .donatetop {
              width: 304px;
              height: 250px;
              background-color: #ffffff;
              overflow: hidden;            
              box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
          }
          .donatebottom {
              width: 304px;
              height: 91px;       
              box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
              margin-top: 19px;
              background: url("../../../assets/images/wydj.png") no-repeat center center;
          }
          .donatetitle {
              font-size: 14px;
              color: #666666;
              text-align: center;
              margin-top: 20px;
              margin-bottom: 16px;
              font-weight: bold;
          }
          .registernum,.realnum {
              width: 279px;
              height: 76px;
              margin: 0 auto;
              // border-radius: 8px;
              margin-bottom: 8px;
              position: relative;
              // box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.19);
              background: url("../../../assets/images/组 20.png") no-repeat;
			  background-size: 100% 100%;
              h2 {
                  font-size: 15px;
                  color: #333333;
                  position: absolute;
                  font-weight: bold;
                  top: 8px;
                  left: 40px;
              } 
              span {
                  margin: 0 auto;
                  display: block;
                  width: 100%;
                  color: #F9696D;
                  font-size: 32px;
                  position: absolute;
                  font-weight: bold;
                  top: 38px;
                  text-align: center;
                //   left: 69px;
                  letter-spacing: 13px;
                  text-indent: 11px;
              }
            }
            .realnum {
               margin-top: 17px; 
               h2 {
                 left: 73px;  
               }
               span {
                   margin: 0 auto;
                   letter-spacing: 13px;
                   text-indent: 11px;
               }
            }
            // .realnum {
            //     width: 277px;
            //     height: 102px;
            //     border: 1px dashed #D5B9B9;
            //     border-radius: 8px;
            //     margin: 0 auto;
            //     h2 {
            //         text-align: center;
            //         color: #F95368;
            //         font-size: 16px;
            //         font-weight: bold;
            //         margin: 7px 0 8px 0;
            //     }
            //     .donatebody {
            //         height: 68px;
            //         margin: 0 9px;
            //         display: flex;
            //         justify-content: space-between;
            //         .organ,.remains {
            //            width: 72px;
            //            height: 68px;
            //            position: relative;
            //            background: url('../../../assets/img/organnum.png') no-repeat center center; 
            //            h2 {
            //                color: #ffffff;
            //                position: absolute;
            //                top: 0px;
            //                left: 20px;
            //            }
            //            span {
            //               color: #F9696D;
            //               font-size: 24px;
            //               position: absolute;
            //               top: 35px;
            //               left: 6px;
            //               letter-spacing: 11px;
            //               font-weight: bold;
            //            }
            //            .remainnum {
            //                text-indent: 23px;
            //            }
            //         }
            //         .Corneal {
            //             width: 95px;
            //             height: 68px;
            //             position: relative;
            //             background: url('../../../assets/img/corneal.png') no-repeat center center;
            //             h2 {
            //                 position: absolute;
            //                 top: 0px;
            //                 left: 32px;
            //                 color: #ffffff;
            //             }
            //             span {
            //                 position: absolute;
            //                 top: 35px;
            //                 left: 4px;
            //                 font-size: 24px;
            //                 font-weight: bold;
            //                 color: #F9696D;
            //                 letter-spacing: 11px;
            //             }
            //         }
            //     }
            // }
        }
    }
}
</style>
